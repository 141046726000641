<template>
  <div :data-rid="`${payload.blockid}-${payload.page}`">
    <div :id="`yandex_rtb_${payload.blockid}-${payload.page}`"></div>
  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, require: true, default: () => ({ blockid: '', page: 1 }) } },
  mounted() {
    const script = document.createElement('script')
    script.innerHTML = `window.yaContextCb.push(()=>{
      Ya.Context.AdvManager.render({
        blockId: '${this.payload.blockid}',
        renderTo: 'yandex_rtb_${this.payload.blockid}-${this.payload.page}',
        pageNumber: ${this.payload.page}
      })
    })`
    const parent = document.querySelector(`[data-rid="${this.payload.blockid}-${this.payload.page}"]`)
    if (parent) {
      parent.appendChild(script)
    }
  }
}
</script>
